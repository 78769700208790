import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';

import { useResetSession } from '@util/providers';

export default function Redirect({ location }) {
  const { t } = useTranslation();
  const resetSession = useResetSession();
  const extension = location.state?.extension || '';
  const pageTitle = getPageTitle(extension);

  useEffect(() => {
    resetSession();
    window.location.href = process.env.BMO_REDIRECT + extension;
  }, [extension, resetSession]);

  function getPageTitle(extension) {
    switch (extension) {
      case '':
        return t('redirectPageTitle.homePage');
      case '/Subscription/CompleteYourProfile':
        return t('redirectPageTitle.profileCreation');
      default:
        return '';
    }
  }
  return (
    <>
      <SEO title={t('page:redirect')} />
      <PageHeader>
        {t('page:redirect')}&nbsp;{pageTitle}
      </PageHeader>
      <div className="pt-3">
        {t('oneMomentPlease')}
        <span
          className="spinner-corner spinner-corner-sm ml-2 mt-1"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </span>
      </div>
    </>
  );
}
